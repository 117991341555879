import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

export default class ImageContast {
    constructor(props) {
        const { content, delay, triggerStart, triggerEnd, isPc, isDivide } = props;
        this.content = content;
        this.delay = delay ?? 0;
        this.image = this.content.querySelector(".image");
        this.triggerStart = triggerStart ?? "top+=5% bottom";

        this.isMobile = document.body.classList.contains("view-mobile");

        this.isPc = isPc ?? false;
        this.isDivide = isDivide ?? false;
        this.triggerEnd = triggerEnd ?? "top-=10% 20%";
        this.init();
    }

    init() {
        this.initStyle();
    }

    initStyle() {
        gsap.set(this.image, {
            // opacity: 0,
            scale: 1.4,
        });
        this.contrastValue = 1;
        this.brightnessValue = 0;
        // this.updateStyle();
        this.anim();
    }

    play() {
        gsap.to(this.image, {
            scale: 1,
            duration: 1.0,
            ease: "power2.out",
            delay: window.innerWidth > 768 ? this.delay : 0,
        });
    }

    anim() {
        ScrollTrigger.create({
            trigger: this.content,
            start: () => this.triggerStart,
            //
            refreshPriority: 0,
            invalidateOnRefresh: true,
            once: true,
            id: "---image",
            // //
            onEnter: () => {
                // gsap.to(this, {
                //     brightnessValue: 1.0,
                //     duration: 1.0,
                //     ease: "power1.inOut",
                //     delay: this.delay,
                //     // onUpdate: () => this.updateStyle(),
                // });

                // gsap.to(this.image, {
                //     opacity: 1,
                //     duration: 0.8,
                //     ease: "power1.out",
                //     delay: this.delay,
                //     // onUpdate: () => this.updateStyle(),
                // });

                if (this.isDivide) {
                    if (window.innerWidth > 768) {
                        if (this.isPc) {
                            this.play();
                        } else {
                            gsap.set(this.image, {
                                scale: 1,
                            });
                        }
                    } else {
                        this.play();
                    }
                } else {
                    this.play();
                }
            },
        });
    }
}
