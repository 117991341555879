import p5 from "p5";
import gsap from "gsap";

export default function sketchInner(s) {
    var img;
    s.preload = () => {
        img = s.loadImage("./line.png");
    };

    var stageWidth = 500;
    var stageHeight = 500;
    var cx, cy;

    s.setup = () => {
        const p = s.createCanvas(stageWidth, stageHeight);
        p.parent("inner-canvas");
        s.angleMode(s.DEGREES);
        cx = s.width / 2;
        cy = s.height / 2;

        const _v = window.innerWidth / s.width;
        // p.elt.setAttribute("data-width", s.width);
        // gsap.set("#inner-canvas", { scale: (_v < 1 ? 1 : _v.toFixed(2)) / 2 });
    };
    s.windowResized = () => {
        if (document.querySelector("#inner-canvas canvas")) {
            // const _v = window.innerWidth / document.querySelector("#inner-canvas canvas").getAttribute("data-width");
            // gsap.set("#inner-canvas", { scale: (_v < 1 ? 1 : _v.toFixed(2)) / 2 });
        }
    };

    var lineSpace = 15;
    var lineWidth = 16;
    var lineHeight = 550;

    var waveHeight = 5;
    var waveStep = 30;
    var waveSpd = 0.8;

    s.draw = () => {
        s.scale(0.5);
        s.background(255);

        // lineHeight = 400 + s.sin(s.frameCount * 1.0) * 550 * 0.4;

        for (var i = 0; i < 72; i++) {
            // var lh = 100 + s.cos(s.frameCount * 1.0 + i * 10) * 100 * 0.5;
            var lh = 150 + s.cos(s.frameCount * 1.2 + i * 5) * 150 * 0.4;
            var tx = cx * 2 + s.cos(s.frameCount * 0.3 + i * 5) * lh;
            var ty = cy * 2 + s.sin(s.frameCount * 0.3 + i * 5) * lh;
            s.push();
            s.translate(tx, ty);
            s.rotate(i * 5 + s.frameCount * 0.3 - 70);
            s.image(img, 0, 0, lineWidth, lh);
            s.pop();
        }
    };

    s.playLoop = () => {
        s.loop();
    };

    s.stopLoop = () => {
        s.noLoop();
    };
}
