import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import SplitText from "gsap/SplitText";

export default class TextMasking {
    constructor(props) {
        const { container, yValue, triggerStart, triggerEnd, stagger, callback, callbackTiming, callbackStart, isNoTrigger, startDelay } = props;

        gsap.registerPlugin(SplitText);
        this.container = container;
        // this.yValue = yValue ?? "100%";
        this.yValue = "130%";
        this.content = gsap.utils.toArray(".txt-split", this.container);

        this.isMobile = document.body.classList.contains("view-mobile");

        this.isNoTrigger = isNoTrigger ?? false;

        this.triggerStart = `top+=50% bottom`;
        if (this.isMobile || window.innerWidth < 768) {
            this.triggerStart = `top+=20% bottom`;
        }
        this.triggerEnd = triggerEnd ?? "+=3000";

        this.callback = callback ?? null;
        this.callbackStart = callbackStart;
        this.callbackTiming = callbackTiming;
        this.isSplit = false;

        this.startDelay = startDelay ?? 0;
        this.init();
    }

    init() {
        if (this.container.hasAttribute("data-auto-split")) {
            const splitText = new SplitText(this.content[0], { type: "lines" });
            const _splitText = new SplitText(splitText.lines, { type: "lines" });

            this.initSplits = splitText;
            this.reInitSplits = splitText;
            this.isSplit = true;
            this.content = _splitText.lines;
            this.initStyle();
        } else {
            const splitText = gsap.utils.toArray(".split-inner", this.container);
            // const _splitText = new SplitText(splitText.lines, { type: "lines" });
            // this.initSplits = splitText;
            // this.reInitSplits = splitText;
            // this.isSplit = true;
            this.content = splitText;
            this.initStyle();
        }
    }

    initStyle() {
        this.content.forEach((c) => {
            if (!c.classList.contains("no-y")) {
                gsap.set(c, { y: this.yValue });
            }
        });

        if (!this.isNoTrigger) {
            this.anim();
        }
    }

    play() {
        gsap.set(this.container, { opacity: 1 });

        this.content.forEach((c, i) => {
            const _callback = (i) => {
                if (i == this.content.length - 1) {
                    if (this.callback) {
                        this.callback();
                    }

                    if (this.isSplit) {
                        // gsap.set(this.container, { minHeight: `${this.container.clientHeight}px` });
                        this.initSplits.revert();
                        this.reInitSplits.revert();
                    }
                }
            };
            // gsap.set(c, { backgroundColor: " pink", onComplete: () => {} });
            // console.log(this);

            const _stagger = window.innerWidth < 768 ? 0.1 : 0.2;
            const _delay = i * _stagger + this.startDelay;
            if (c.classList.contains("no-y")) {
                gsap.to(c, {
                    opacity: 1,
                    duration: 0.8,
                    ease: "power2.out",
                    delay: _delay,
                    onComplete: () => _callback(i),
                });
            } else {
                gsap.to(c, {
                    y: "0%",
                    duration: 0.7,
                    ease: "power4.out",
                    delay: _delay,
                });
                gsap.to(c, {
                    opacity: 1,
                    duration: 0.7,
                    ease: "power4.out",
                    delay: _delay,
                    onComplete: () => _callback(i),
                });
            }

            if (i == 0 && this.callbackStart) {
                if (this.callbackTiming) {
                    gsap.delayedCall(0.9 * this.callbackTiming, () => {
                        this.callbackStart();
                    });
                } else {
                }
            } else {
            }
        });
    }

    anim() {
        ScrollTrigger.create({
            trigger: this.container,
            start: () => this.triggerStart,
            end: () => this.triggerEnd,
            once: true,
            // markers: true,
            id: "masking",
            // onUpdate: self => {
            //     // 스크롤 속도를 감지하여 빠르게 스크롤 시 애니메이션 완성
            //     console.log(self.getVelocity())
            //     if (self.getVelocity() > 4000) {
            //         this.playTimeline(true)

            //     }
            // },
            onEnter: () => {
                this.play();
            },
        });
    }
}
