import p5 from "p5";
import gsap from "gsap";

export default function sketchInner(s) {
    var img;
    s.preload = () => {
        img = s.loadImage("./line.png");
    };

    var stageWidth = 900;
    var stageHeight = 600;
    var cx, cy;

    class Obj {
        constructor(i){
            this.idx = i;
        }
        update(){
            var lh = s.lineHeight + s.cos(s.frameCount * s.waveSpd + this.idx * s.waveStep) * s.lineHeight * s.waveRatio;
            var tx = 0 + this.idx * (lineWidth + lineSpace)
            var ty = cy * 2 + s.cos(s.frameCount * s.waveSpd + this.idx * s.waveHeight * .5) * s.waveHeight - lh * .5;
            s.image(img, tx, ty, lineWidth, lh);
        }
    }

    var objs = [];

    s.setup = () => {
        const p = s.createCanvas(stageWidth, stageHeight);
        p.parent("grid-visual");
        s.angleMode(s.DEGREES);
        cx = s.width / 2;
        cy = s.height / 2;

        for(var i = 0; i < 59; i++){
            objs.push(new Obj(i));
        }

        s.movIn();
    }

    var lineSpace = 15;
    var lineWidth = 16;
    s.lineHeight = 600;
    s.waveHeight = 0;
    s.waveStep = 10;
    s.waveRatio = 0.3;
    s.waveSpd = 4;

    s.draw = () => {
        s.scale(0.5);
        s.background(255);

        for(var i = 0; i < objs.length; i++){
            var obj = objs[i];
            obj.update();
        }
    }

    s.movIn = () => {
        gsap.to( s, { duration: 2.5, waveHeight: 0, waveRatio: 0.5, lineHeight: 100, ease: "expo.inOut", onComplete: function(){
            gsap.to( s, { duration: 2.4, waveHeight: 80, waveStep: 0, waveRatio: 0.0, lineHeight: 450, ease: "power1.inOut", delay: 0.5,  onComplete: function(){
                gsap.delayedCall(1, s.movOut);
            } });
        } }); 

    }

    s.movOut = () => {
        gsap.to( s, { duration: 3.3, waveHeight: 0, waveStep: 10, waveRatio: 0.25, lineHeight: 600, ease: "expo.out", onComplete: function(){
            gsap.delayedCall(0.1, s.movIn);
        } });
    }
    s.playLoop = () => {
        s.loop();
    };

    s.stopLoop = () => {
        s.noLoop();
    };
}
