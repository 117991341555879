import p5 from "p5";
import gsap from "gsap";

export default function sketch(s) {
    var img;
    var isDraw = false;
    s.preload = () => {
        img = s.loadImage("./line.png");
    };

    var stageWidth = 900;
    var stageHeight = 600;

    var objs = [];

    class Obj {
        constructor(idx) {
            this.idx = idx;
            this.idx2 = 30 + 30 * s.cos(this.idx * 2);
        }

        update(i) {
            var tx = this.idx * (lineWidth + lineSpace);
            var ty = sy + this.idx2 * s.sin(this.idx2 * waveStep + s.frameCount * 1.5) * waveHeight;
            var th = lineHeight + s.sin(this.idx2 * waveStep * 1.5 + s.frameCount * 1.5) * lineHeight * 0.3;
            s.image(img, tx, ty, lineWidth, th);

            if (tx < -30) {
                this.idx = last;
                this.idx2 = 30 + 30 * s.cos(this.idx * 2);
                last++;
            }
        }
    }

    var lineCount = 65;
    var last = lineCount;

    s.setup = () => {
        const p = s.createCanvas(stageWidth, stageHeight);
        p.parent("canvas-wrap");
        s.angleMode(s.DEGREES);
        const _v = window.innerWidth / p.elt.clientWidth;
        gsap.set("#canvas-wrap", { scale: _v < 1 ? 1 : _v.toFixed(2) });

        for (var i = 0; i < lineCount; i++) {
            objs.push(new Obj(i));
        }
    };

    s.windowResized = () => {
        if (document.querySelector("#defaultCanvas0")) {
            const _v = window.innerWidth / document.querySelector("#defaultCanvas0").clientWidth;
            gsap.set("#canvas-wrap", { scale: _v < 1 ? 1 : _v.toFixed(2) });
        }
    };

    var sy = 300;

    var lineSpace = 15;
    var lineWidth = 16;
    var lineHeight = 500;

    var waveHeight = 5;
    var waveStep = 5;
    var waveSpd = 0.8;

    s.draw = () => {
        s.scale(0.5);
        s.background(255);

        for (var i = 0; i < objs.length; i++) {
            var obj = objs[i];
            obj.update(i);
        }
        if (!isDraw) {
            isDraw = true;
            gsap.to(".section-main canvas", { opacity: 1, duration: 1.0, ease: "power1.inOut" });
            gsap.set(".section-main canvas", { scaleY: 0.1 });
            gsap.to(".section-main canvas", { scaleY: 1, duration: 2.0, ease: "power1.out", delay: 0.0 });
        }
    };
    s.playLoop = () => {
        s.loop();
    };

    s.stopLoop = () => {
        s.noLoop();
    };
}
