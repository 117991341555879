import p5 from "p5";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollSmoother from "gsap/dist/ScrollSmoother";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import MotionPathPlugin from "gsap/MotionPathPlugin";
import DrawSVGPlugin from "gsap/DrawSVGPlugin";
// swiper
import "swiper/css";
import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./style/common.css";
import { TextFill, TextFilp, TextMasking, TextTranslate, ImageContast, ButtonExtend } from "./Module";
import CardExtend from "./CardExtend";
import CardBGAnim from "./CardBGAnim2";
import sketch from "./visualDTW";
import SVGText from "./SVGText";
export default class DTW {
    constructor() {
        gsap.registerPlugin(DrawSVGPlugin);
        this.init();
    }

    init() {
        // gsap.delayedCall(0.3, () => {});

        this.sectionKeyvisual();
        gsap.delayedCall(0.01, () => {
            this.sectionRound();
            this.sectionSpeaker();
            this.sectionExpect();
            this.sectionProgram();
            ScrollTrigger.refresh();
        });

        gsap.delayedCall(0.1, () => {
            this.sectionMain();
            ScrollTrigger.refresh();
        });
    }

    sectionMain() {
        const section = document.querySelector(".section-main");
        // const typo = section.querySelectorAll(".fill-rect");
        const duration = window.innerWidth > 768 ? 1 : 0.7;
        //
        // gsap.utils.toArray(".char-path-container", section).forEach((content, i) => {
        //     const _text = new TextFill({ content: content });
        //     _text.play();
        // });
        // gsap.to(".typo-rect", { width: "100%", duration: 0.7, ease: window.innerWidth > 768 ? "power2.out" : "power3.out", stagger: 0.1 });
        gsap.utils.toArray(".info-value-container", section).forEach((container, i) => {
            const targetValue = container.querySelector(".info-value-hidden").innerHTML;
            // const _delay = 0.3 * i
            const textFilp = new TextFilp({ _result: targetValue, _delay: 0, content: container.querySelector(".info-value") });

            textFilp.play();
        });

        const _svg = new SVGText({ content: section.querySelector(".new-typo-container"), isNotTrigger: true, stagger: 0.2 });
        if (window.innerWidth > 768) {
            _svg.play("pc");
        } else {
            _svg.play("mo");
        }

        ScrollTrigger.refresh();
    }

    sectionKeyvisual() {
        const sketchInstance = new p5(sketch);

        ScrollTrigger.create({
            trigger: ".section-main",
            start: () => `top-=30px top`,
            end: () => `bottom top`,
            id: "p5",
            onToggle: (self) => {
                if (self.isActive) {
                    sketchInstance.playLoop();
                } else {
                    sketchInstance.stopLoop();
                }
            },
        });
    }

    sectionProgram() {
        const section = document.querySelector(".section-program");

        gsap.utils.toArray(".graphic", section).forEach((container, i) => {
            new CardExtend({ container: container, bgScale: 0.35 });
        });

        new SVGText({ content: section.querySelector(".new-typo-container"), stagger: 0.2 });
        // const typo = section.querySelector('.new-typo.is-mo');
        // ScrollTrigger.create({
        //     trigger: typo,
        //     start: () => `${100 / typo.getAttribute('data-length')}% bottom`,
        //     markers: true,
        //     onEnter: () => {
        //         const duration = window.innerWidth > 768 ? 1 : 0.7;
        //         const _ease = window.innerWidth > 768 ? "power2.out" : "power1.out";
        //         const _stagger = window.innerWidth > 768 ? 0.2 : 0.15;

        //         gsap.utils.toArray('.typo-mask', typo).forEach((t, i) => {
        //             gsap.to(t, { width: '100%', duration: duration, ease: _ease, delay: _stagger * t.getAttribute('data-index')})

        //         })

        //         const line_ease = window.innerWidth > 768 ? "power3.out" : "power1.out";
        //         const line_duration = window.innerWidth > 768 ? 3.5 : 1.7;

        //         gsap.utils.toArray('.typo-line', typo).forEach((line, i) => {
        //             gsap.to(line, {
        //                 drawSVG: "100%",
        //                 duration: line_duration,
        //                 ease: line_ease,
        //                 delay: i * 0.5,
        //                 onComplete: () => {
        //                 },
        //             });
        //         })

        //     }
        // })

        ScrollTrigger.refresh();
    }

    sectionSpeaker() {
        const section = document.querySelector(".section-speaker");
        const _speaker = gsap.utils.toArray(".speaker", section).filter((s) => !s.classList.contains("space"));
        _speaker.forEach((content, i) => {
            new ImageContast({ content: content, delay: content.getAttribute("data-delay") * 0.2 });
        });

        new SVGText({ content: section.querySelector(".new-typo-container"), stagger: 0.2 });
    }
    sectionRound() {
        const section = document.querySelector(".section-roundtable");

        gsap.utils.toArray(".image-wrap", section).forEach((content, i) => {
            new ImageContast({ content: content, delay: i * 0.2, isPc: i < 3, isDivide: true });
        });

        const _swiper = section.querySelector(".swiper");

        let swiper;
        const initSwiper = () => {
            swiper = new Swiper(_swiper, {
                // Default parameters
                slidesPerView: 2.15,
                spaceBetween: 12,
                slidesOffsetBefore: 20,
                slidesOffsetAfter: 20,
                modules: [Navigation],
                navigation: {
                    nextEl: section.querySelector(".swiper-button-next"),
                    prevEl: section.querySelector(".swiper-button-prev"),
                },
            });
        };

        const destroySwiper = () => {
            if (swiper) {
                swiper.destroy(true, true);
                swiper = undefined;
                gsap.utils.toArray(".swiper-slide", _swiper).forEach((s) => {
                    gsap.set(s, { clearProps: "all" });
                });
            }
        };
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                destroySwiper();
            } else {
                if (!swiper) {
                    initSwiper();
                }
            }
        };

        window.addEventListener("resize", handleResize);

        if (window.innerWidth > 768) {
            initSwiper();
        }
    }

    sectionExpect() {
        const section = document.querySelector(".section-expect");
        const _swiper = section.querySelector(".swiper");
        const swiper = new Swiper(_swiper, {
            // Default parameters
            slidesPerView: 1.15,
            spaceBetween: 12,
            slidesOffsetBefore: 20,
            slidesOffsetAfter: 20,
            modules: [Pagination],
            pagination: {
                el: _swiper.querySelector(".swiper-pagination"),
                type: "bullets",
                clickable: true,
            },
            breakpoints: {
                // when window width is >= 320px
                769: {
                    slidesPerView: 3,
                    spaceBetween: window.innerWidth * 0.014,
                    slidesOffsetBefore: 0,
                    slidesOffsetAfter: 0,
                },
            },
        });

        new SVGText({ content: section.querySelector(".new-typo-container"), stagger: 0.2 });
        ScrollTrigger.refresh();
        // const typo = section.querySelector('.new-typo.is-mo');
        // ScrollTrigger.create({
        //     trigger: typo,
        //     start: () => `${100 / typo.getAttribute('data-length')}% bottom`,
        //     markers: true,
        //     onEnter: () => {
        //         const duration = window.innerWidth > 768 ? 1 : 0.7;
        //         const _ease = window.innerWidth > 768 ? "power2.out" : "power1.out";
        //         const _stagger = window.innerWidth > 768 ? 0.2 : 0.15;

        //         gsap.utils.toArray('.typo-mask', typo).forEach((t, i) => {
        //             gsap.to(t, { width: '100%', duration: duration, ease: _ease, delay: _stagger * t.getAttribute('data-index')})

        //         })

        //         const line_ease = window.innerWidth > 768 ? "power3.out" : "power1.out";
        //         const line_duration = window.innerWidth > 768 ? 3.5 : 1.7;

        //         gsap.utils.toArray('.typo-line', typo).forEach((line, i) => {
        //             gsap.to(line, {
        //                 drawSVG: "100%",
        //                 duration: line_duration,
        //                 ease: line_ease,
        //                 delay: i * 0.5,
        //                 onComplete: () => {
        //                 },
        //             });
        //         })

        //     }
        // })
        // gsap.utils.toArray(".char-path-container", section).forEach((content, i) => {
        // new TextFill({ content: content, stagger: 0.2 });
        // });

        new CardBGAnim(section.querySelectorAll(".content-card"));
    }
}
