import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

export default class TextTranslate {
    constructor(props) {
        const { container, yValue, triggerStart, triggerEnd } = props;

        this.container = container;
        // this.yValue = yValue ?? 80;
        this.yValue = window.innerWidth > 769 ? 400 : 150;

        this.content = this.container.classList.contains("txt-fade") ? [this.container] : gsap.utils.toArray(".txt-fade", this.container);

        this.isMobile = document.body.classList.contains("view-mobile");
        // this.triggerStart = triggerStart ?? "top-=10% bottom-=50px";

        if (this.isMobile) {
            this.triggerStart = triggerStart ?? `top${this.yValue}px bottom`;
        } else {
            this.triggerStart = triggerStart ?? `top-=10% bottom`;
        }

        this.triggerEnd = triggerEnd ?? `+=${this.container.clientHeight * 0.8}px`;
        this.init();
    }

    init() {
        this.initStyle();
    }

    initStyle() {
        this.content.forEach((c) => {
            if (!c.classList.contains("no-y")) {
                gsap.set(c, { y: this.yValue });
            } else {
                gsap.set(c, { opacity: 0 });
            }
        });
        gsap.delayedCall(0.01, () => {
            this.anim();
        });
    }

    anim() {
        this.content.forEach((c, i) => {
            const _duration = 1.0;
            // c.classList.add("add-bg");
            let yv = this.yValue;

            if (c.classList.contains("no-y")) {
                yv = 0;
            }
            let st = window.innerWidth > 768 ? `15%-=${yv}px bottom` : `10%-=${yv}px bottom`;
            // }

            ScrollTrigger.create({
                trigger: c,
                start: () => st,
                // end: () => this.triggerEnd,
                // // // //
                once: true,
                // refreshPriority: 0,
                id: `translate + ${i}`,
                // markers: true,

                onEnter: () => {
                    if (c.classList.contains("no-y")) {
                        gsap.to(c, {
                            opacity: 1,
                            duration: 0.7,
                            ease: "power2.inOut",
                            // delay: _delay,
                        });
                    } else {
                        gsap.to(c, {
                            y: 0,
                            duration: _duration,
                            ease: "power4.out",
                            // // delay: _delay,
                        });
                        gsap.to(c, {
                            opacity: 1,
                            duration: _duration - 0.2,
                            ease: "expo.inOut",
                            // // delay: _delay,
                        });
                    }
                },
            });
        });

        ScrollTrigger.refresh();
        return;
        const _duration = 0.8;
        ScrollTrigger.create({
            trigger: this.container,
            start: () => this.triggerStart,
            // end: () => this.triggerEnd,
            // // // //
            // once: true,
            id: "texxxt",

            onEnter: () => {
                this.content.forEach((c, i) => {
                    const _delay = i * 0.2;
                    if (c.classList.contains("no-y")) {
                        gsap.to(c, {
                            opacity: 1,
                            duration: _duration,
                            ease: "power2.inOut",
                            delay: _delay,
                        });
                    } else {
                        gsap.to(c, {
                            y: 0,
                            duration: _duration,
                            ease: "power2.out",
                            delay: _delay,
                        });
                        gsap.to(c, {
                            opacity: 1,
                            duration: _duration,
                            ease: "power2.inOut",
                            delay: _delay,
                        });
                    }
                });
            },
        });
    }
}
