import gsap from "gsap";
export default class TextFilp {
    // console.log({props})

    constructor(props) {
        const { _result, _delay, content, init, step } = props;
        this.output = "";
        this.delay = _delay;
        this.target = _result;
        this.content = content;
        this.count_step = step;
        this.isMobile = document.body.classList.contains("view-mobile");
        if (init) {
            this.content.innerHTML = this.target;
        }
    }

    play() {
        const that = this;

        gsap.delayedCall(this.delay, () => {
            this.anim();
        });
    }

    anim() {
        var target = this.target;

        var targetArr;
        // var source = "ABCEFGHLKLMOPRSTUVXYZ";
        var source = "ABCEFGHLKLMOPRSTUVXYZ";
        // var source1 = "abcdefghijklmnopqrstuvwxyzABCEFGHLKLMOPRSTUVXYZ";
        var source1 = "0123456789ABCEFGHLKLMOPRSTUVXYZ";
        var sourceArr;
        var timer = null;
        var baseCnt = 0;
        var moveCnt = 0;
        var textCnt = 0;
        var isMove = 0;

        const COUNT_STEP = this.count_step ?? 6;
        const outputArr = [];

        const delayStart = (val) => {
            clearInterval(timer);
            this.output = "";

            // outputArr = [];
            // baseCnt = 0;
            // moveCnt = 0;
            // textCnt = 0;
            // isMove = 0;

            var delay = setInterval(function () {
                startText(val);
                clearInterval(delay);
            }, 10);
        };

        const startText = (val) => {
            targetArr = target.split("");
            if (val == 0) {
                sourceArr = source.split("");
            } else {
                sourceArr = source1.split("");
            }
            for (var i = 0; i < targetArr.length; i++) {
                outputArr.push("");
            }
            timer = setInterval(changeText, COUNT_STEP);
        };

        const changeText = () => {
            if (!this.isMobile) {
                gsap.set(this.content, { textOverflow: "ellipsis" });
            }

            var str = "";
            // gsap.set(this.content, { textOverflow: "unset" });
            for (var i = 0; i < outputArr.length; i++) {
                var t = targetArr[i];
                var c = sourceArr[Math.floor(Math.random() * sourceArr.length)];
                if (i > moveCnt - 1) {
                    // c = "--";
                    c = "";
                    // c = "--";
                }
                if (i < textCnt) {
                    c = t;
                }
                if (t == " ") {
                    c = " ";
                }
                str += c;
            }

            this.output = str;

            if (baseCnt % 2 == 0 && isMove == 0) {
                moveCnt++;
                if (moveCnt > targetArr.length) {
                    isMove = 1;
                }
            }
            if (baseCnt % 2 == 0 && isMove == 1) {
                textCnt++;
            }
            if (textCnt > targetArr.length) {
                clearInterval(timer);
            }
            baseCnt++;
            this.content.innerHTML = this.output;
        };
        delayStart(1);
    }
}
