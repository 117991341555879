import p5 from "p5";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollSmoother from "gsap/dist/ScrollSmoother";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import MotionPathPlugin from "gsap/MotionPathPlugin";
import DrawSVGPlugin from "gsap/DrawSVGPlugin";
// swiper
export default class SVGText {
    constructor(props) {
        gsap.registerPlugin(DrawSVGPlugin);
        const { content, stagger, isNotTrigger } = props;
        this.typo = content;
        this.line = gsap.utils.toArray(".typo-line", this.typo);
        this.stagger = stagger;
        this.isNotTrigger = isNotTrigger;

        this.init();
    }

    init() {
        this.line.forEach((l) => {
            const svg = l.closest("svg");
            const elW = svg.clientWidth;
            const svgW = svg.getAttribute("width");
            const ratio = elW / svgW;
            const totalLength = Math.ceil(l.getTotalLength() * ratio);
            l.setAttribute("data-value", totalLength * 1.7);
        });
        this.line.forEach((l) => {
            gsap.set(l, { drawSVG: 0 });
        });
        if (!this.isNotTrigger) {
            this.anim();
        }
        ScrollTrigger.refresh();
    }

    play(device) {
        const typo = device == "mo" ? this.typo.querySelector(".is-mo") : this.typo.querySelector(".is-pc");
        const _duration = window.innerWidth > 768 ? 1.5 : 2.0;
        const _ease = window.innerWidth > 768 ? "expo.out" : "expo.out";

        const line_ease = window.innerWidth > 768 ? "expo.out" : "expo.out";
        const line_duration = window.innerWidth > 768 ? 1.8 : 1.6;

        let _stagger = this.stagger;

        if (this.stagger === 0.2 && device == "mo") {
            _stagger = 0.15;
        }

        gsap.utils.toArray(".typo-mask", typo).forEach((t, i) => {
            let _dt = _duration;
            let _width = `${2 + t.getAttribute("data-ratio") * 100}%`;

            if (window.innerWidth < 768) {
                const ratio = t.getAttribute("data-ratio");
                _dt = ratio * 0.6 + 0.9;
            } else {
                // pc
                const ratio = t.getAttribute("data-ratio");
                _dt = ratio * 1.4 + 0.9;
            }

            gsap.to(t, {
                width: _width,
                duration: _dt,
                ease: _ease,
                // delay: _stagger * t.getAttribute("data-index"),
                onComplete: () => {
                    if (this.line.length == 0) {
                        if (i == gsap.utils.toArray(".typo-mask", typo).length - 1) {
                            this.typo.classList.add("is-loaded");
                        }
                    }
                    gsap.set(t, { width: "100%" });
                },
            });
        });

        if (this.line) {
            gsap.utils.toArray(".typo-line", typo).forEach((line, i) => {
                const length = line.getAttribute("data-value");
                gsap.to(line, {
                    drawSVG: "100%",
                    duration: line_duration,
                    ease: line_ease,
                    // delay: i * 0.5,
                    onComplete: () => {},
                });
            });
        }
    }

    anim() {
        const _this = this;
        ScrollTrigger.matchMedia({
            "(min-width: 769px)": function () {
                // console.log(3, that);
                const _trigger = _this.typo.querySelector(".is-pc");

                ScrollTrigger.create({
                    trigger: _this.typo,
                    start: () => `top+=${100 / _trigger.getAttribute("data-length")}% bottom`,
                    refreshPriority: 0,
                    // once: true,
                    // markers: true,
                    id: "svg",
                    onEnter: () => {
                        _this.play("pc");
                    },
                });
                if (!_this.typo.classList.contains("is-loaded")) {
                    ScrollTrigger.refresh();
                }
            },

            // small
            "(max-width: 768px)": function () {
                const _trigger = _this.typo.querySelector(".is-mo");

                ScrollTrigger.create({
                    trigger: _this.typo,
                    start: () => `top+=${100 / _trigger.getAttribute("data-length")}% bottom`,
                    refreshPriority: 0,
                    // markers: true,
                    onEnter: () => {
                        _this.play("mo");
                    },
                });
                if (!_this.typo.classList.contains("is-loaded")) {
                    ScrollTrigger.refresh();
                }
            },
        });

        ScrollTrigger.refresh();
    }
}
