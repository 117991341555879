import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

export default class CardBGAnim {
    constructor(contents) {
        this.contents = contents;
        this.isMobile = document.body.classList.contains("view-mobile");

        this.init();
    }

    init() {
        this.initStyle();
    }

    initStyle() {
        this.contents.forEach((card, i) => {
            const _bg = card.querySelector(".card-background");
            const _sub = card.querySelectorAll(".txt-small");
            const _graphic = card.querySelector(".card-graphic");

            if (_graphic) {
                gsap.set(_graphic, {
                    transformOrigin: "50% 50%",
                    y: window.innerWidth > 768 ? "200%" : "150%",
                    scaleX: -1,
                });
            }

            gsap.set(_sub, { autoAlpha: 0 });
        });

        this.anim();
    }

    anim() {
        this.contents.forEach((card, i) => {
            const _bg = card.querySelector(".card-background");
            const _sub = card.querySelectorAll(".txt-small");
            const _graphic = card.querySelector(".card-graphic");

            const _delay = 0.15 * i;
            ScrollTrigger.create({
                trigger: card,
                start: () => `top+=10% bottom`,
                once: true,
                onEnter: () => {
                    gsap.to(_sub, {
                        autoAlpha: 1,
                        duration: 0.9,
                        ease: "expo.inOut",
                        delay: _delay,
                    });
                },
            });

            ScrollTrigger.create({
                trigger: card,
                start: () => "top+=60% bottom",
                once: true,
                onEnter: () => {
                    gsap.to(_bg, { autoAlpha: 1, duration: 1, delay: 0.5, ease: "power1.inOut", delay: _delay });

                    // gsap.to(_graphic, { autoAlpha: 1, duration: 0.8, ease: "power2.inOut", delay: _delay });
                    gsap.to(_graphic, { y: "0%", duration: 1.6, ease: "expo.out", delay: _delay });
                },
            });
        });
    }
}
