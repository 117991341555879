import p5 from "p5";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollSmoother from "gsap/dist/ScrollSmoother";
import ScrollToPlugin from "gsap/ScrollToPlugin";
// swiper
import "swiper/css";
import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./style/common.css";
import { TextFill, TextFilp, TextMasking, TextTranslate, ImageContast, ButtonExtend, CardImageText } from "./Module";
import sketch from "./visualTelcoLLM";
import CardExtend from "./CardExtend";
import CardBGAnim from "./CardBGAnim";
import sketchInner from "./visualTelcoLLMBox";
import SVGText from "./SVGText";

export default class GTAA_TelcoLLM {
    constructor() {
        this.init();
    }

    init() {
        gsap.delayedCall(0.2, () => {
            this.sectionMain();
        });
        gsap.delayedCall(0.01, () => {
            //
            // this.sectionObjectives()
            this.sectionAbout();
            this.sectionAdvantages();

            this.sectionUsing();
            this.sectionCollaborator();

            this.sectionPartnership();
            this.sectionAgent();

            // this.sectionFounding()
            // this.sectionFoundingMembers()
            // this.sectionProgram();
            // this.sectionSpeaker();
            // this.sectionRound();
            // this.sectionInnovation()
            // this.sectionMilestones()
            // this.sectionTelcoLLM();

            // this.sectionExpect();

            ScrollTrigger.refresh();
        });
        gsap.delayedCall(0.3, () => {
            this.sectionKeyvisual();

            ScrollTrigger.refresh();
        });
    }

    sectionMain() {
        const section = document.querySelector(".section-main");

        const _svg = new SVGText({ content: section.querySelector(".new-typo-container"), isNotTrigger: true, stagger: 0.2 });
        if (window.innerWidth > 768) {
            _svg.play("pc");
        } else {
            _svg.play("mo");
        }

        ScrollTrigger.refresh();
    }

    sectionKeyvisual() {
        const sketchInstance = new p5(sketch);

        ScrollTrigger.create({
            trigger: ".section-main",
            start: () => `top-=30px top`,
            end: () => `bottom top`,
            id: "p5",
            onToggle: (self) => {
                if (self.isActive) {
                    sketchInstance.playLoop();
                } else {
                    sketchInstance.stopLoop();
                }
            },
        });

        ScrollTrigger.refresh();
    }

    sectionAbout() {
        const section = document.querySelector(".section-business");

        const sketchInstance = new p5(sketchInner);
        sketchInstance.stopLoop();
        ScrollTrigger.create({
            trigger: section.querySelector("#grid-visual"),
            start: () => `top-=30px bottom`,
            end: () => `bottom top`,
            id: "p5",
            // markers: true,
            onToggle: (self) => {
                if (self.isActive) {
                    sketchInstance.playLoop();
                } else {
                    sketchInstance.stopLoop();
                }
            },
        });

        ScrollTrigger.refresh();
    }

    sectionAdvantages() {
        const section = document.querySelector(".section-advantages");

        new SVGText({ content: section.querySelector(".new-typo-container"), stagger: 0.2 });

        gsap.utils.toArray(".card", section).forEach((card, i) => {
            new CardImageText({ content: card, isLight: card.classList.contains("is-light") ? true : false });
        });

        ScrollTrigger.refresh();
    }

    sectionUsing() {
        const section = document.querySelector(".section-using");

        gsap.utils.toArray(".card-graphic", section).forEach((content) => {
            new CardImageText({ content: content });
        });

        ScrollTrigger.refresh();
    }

    sectionPartnership() {
        const section = document.querySelector(".section-partnership");
        new SVGText({ content: section.querySelector(".new-typo-container"), stagger: 0.2 });
    }

    cardFilter(card) {}

    sectionAgent() {
        const section = document.querySelector(".section-agent");
        new SVGText({ content: section.querySelector(".new-typo-container"), stagger: 0.2 });

        gsap.utils.toArray(".card-agent-container", section).forEach((container) => {
            gsap.utils.toArray(".card-agent", container).forEach((card, i) => {
                new CardImageText({ content: card, index: i, triggerStart: `top+=5% bottom` });
            });
        });

        ScrollTrigger.refresh();
    }
    sectionFounding() {
        const section = document.querySelector(".section-founding");
        gsap.utils.toArray(".typo-mask-container", section).forEach((content, i) => {
            new TextFill({ content: content, stagger: 0.2 });
        });
    }

    sectionRound() {
        const section = document.querySelector(".section-roundtable");

        gsap.utils.toArray(".image-wrap", section).forEach((content, i) => {
            new ImageContast({ content: content, delay: i * 0.15 });
            // console.log({ i });
        });
    }
    sectionInnovation() {
        const section = document.querySelector(".section-innovation");

        new CardBGAnim(section.querySelectorAll(".content-card"));
    }

    sectionCollaborator() {
        const section = document.querySelector(".section-collaborator");

        gsap.utils.toArray(".card-collborator").forEach((card, i) => {
            const logo = card.querySelector(".card-logo");
            gsap.set(logo, { opacity: 0 });
            ScrollTrigger.create({
                trigger: card,
                start: () => `top+=30% bottom`,
                once: true,
                onEnter: () => {
                    gsap.to(logo, { opacity: 1, duration: 0.8, ease: "power1.inOut", delay: window.innerWidth > 768 ? i * 0.15 : 0 });
                },
            });
        });
    }

    sectionFoundingMembers() {
        const section = document.querySelector(".section-founding-embers");

        // gsap.utils.toArray(".typo-mask-container", section).forEach((content, i) => {

        //     const _text = new TextFill({ content: content,  isUp: true });

        // });
        // gsap.utils.toArray(".content-card", section).forEach((container, i) => {
        //     new CardExtend({ container: container, bgScale: 0.35 });
        // });

        gsap.utils.toArray(".content-card", section).forEach((container) => {
            ScrollTrigger.create({
                trigger: container,
                start: () => `top+=30% bottom`,
                once: true,
                onEnter: () => {
                    gsap.to(container.querySelector(".card-background"), { opacity: 1, duration: 0.7, ease: "power1.inOut" });
                },
            });
        });

        new CardBGAnim(section.querySelectorAll(".content-card"));
    }
}
