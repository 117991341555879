import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import SplitText from "gsap/SplitText";

export default class ButtonExtend {
    constructor(props) {
        gsap.registerPlugin(SplitText);

        const { content, triggerStart, triggerEnd, noTrigger } = props;
        this.content = content;

        this.triggerStart = triggerStart ?? `top bottom`;
        this.triggerEnd = triggerEnd ?? "top-=10% 20%";
        this.text = this.content.querySelector(".txt");

        this.icon = this.content.querySelector(".icon-wrap span");

        this.isRotate = false;
        if (this.icon.classList.contains("icon-arrow-right")) {
            this.isRotate = true;
            gsap.set(this.icon, { x: "-100%", y: "100%" });
        }

        this.noTrigger = noTrigger ?? false;
        this.textArr = [];
        this.background = this.content.querySelector(".background");
        this.init();
    }

    init() {
        // console.log("new ButtonExtend");

        this.initStyle();
    }

    initStyle() {
        // this.textArr = new SplitText(this.text, { type: "words" }).words.reverse();
        // gsap.set(this.textArr, { opacity: 0 });
        // console.log(this.textArr);

        if (!this.noTrigger) {
            this.anim();
        }
    }

    play() {
        gsap.to(this.background, {
            width: "100%",
            duration: 0.5,
            ease: "power1.inOut",
            delay: 0,
        });
        // Background : opacity
        // gsap.to(this.background, {
        //     opacity: 1,
        //     duration: 0.4,
        //     ease: "power1.inOut",
        //     delay: 0,
        // });

        gsap.to(this.text, {
            opacity: 1,
            duration: 0.8,
            ease: "power2.inOut",
            onStart: () => {
                gsap.to(this.icon, {
                    x: "0%",
                    y: "0%",
                    duration: 0.4,
                    ease: "power2.out",
                    delay: 0.4,
                });
            },
        });
    }

    anim() {
        ScrollTrigger.create({
            trigger: this.content,
            start: () => this.triggerStart,
            //
            refreshPriority: 0,
            invalidateOnRefresh: true,
            once: true,

            onEnter: () => {
                this.play();
                // this.textArr.forEach((t, i) => {
                //     const _delay = 0;
                //     gsap.to(t, { y: "0%", duration: 0.45, ease: "power2.out", delay: _delay });
                //     gsap.to(t, {
                //         opacity: 1,
                //         duration: 0.8,
                //         ease: "power1.inOut",
                //         delay: _delay,
                //         onStart: () => {
                //             if (i == this.textArr.length - 1) {
                //                 gsap.to(this.icon, {
                //                     x: "0%",
                //                     duration: 0.4,
                //                     ease: "power2.out",
                //                     delay: 0.4,
                //                 });
                //             }
                //         },
                //     });
                // });

                // Background : opacit
            },
        });

        const button_delay = 0;

        // gsap.to(button.querySelector('.button-background'), {
        //     scaleX: 1,
        //     duration: 0.75,
        //     ease: 'power2.out',
        //     delay: button_delay,
        // })
        // // Background : opacity
        // gsap.to(button.querySelector('.button-background'), {
        //     opacity: 1,
        //     duration: 0.65,
        //     ease: 'power1.inOut',
        //     delay: button_delay,
        // })
        // // Background : text
        // gsap.to(button.querySelector('.answer-text'), {
        //     opacity: 1,
        //     duration: 0.45,
        //     ease: 'power1.out',
        //     delay: button_delay + 0.45,
        // })
    }
}
