import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import DrawSVGPlugin from "gsap/DrawSVGPlugin";
// swiper
export default class TextFill {
    constructor(props) {
        const { content, direction, triggerStart, triggerEnd, stagger, isReverse, isUp, isFooter, startLineIndex, callback } = props;
        this.content = content;
        this.direction = direction;
        // this.triggerStart = triggerStart ?? "33% bottom";
        this.triggerStart = triggerStart ?? "5%";

        this.isMobile = document.body.classList.contains("view-mobile");
        this.typos = gsap.utils.toArray(".typo-mask", this.content);

        this.stagger = stagger ?? 0.1;

        if (this.stagger == 0.2) {
            if (window.innerWidth < 768) {
                this.stagger = 0.15;
            }
        }

        this.startLineIndex = 0;
        this.isReverse = isReverse;
        this.isUp = isUp ?? false;

        this.line = this.content.querySelector(".typo-line") ?? null;
        this.callback = callback;

        this.played = false;
        // this.svg = this.init();
        this.isFooter = isFooter ?? false;
        this.init();
    }

    init() {
        this.initStyle();
        // console.log("new TextMasking");
    }

    initStyle() {
        if (this.line) {
            gsap.utils.toArray(".typo-line", this.content).forEach((line) => {
                gsap.set(line, { drawSVG: 0 });
            });
        }
        this.anim();
    }

    drawLine(device) {
        let svg;
        if (device) {
            svg = device === "pc" ? this.content.querySelector(".is-pc") : this.content.querySelector(".is-mo");
        } else {
            svg = this.content;
        }

        const _ease = window.innerWidth > 768 ? "power3.out" : "power1.out";
        const _duration = window.innerWidth > 768 ? 3.5 : 1.7;

        gsap.utils.toArray(".typo-line", svg).forEach((line, i) => {
            if (!line.hasAttribute("data-load")) {
                // line.setAttribute("data-load", true);
                gsap.to(line, {
                    drawSVG: "100%",
                    duration: _duration,
                    ease: _ease,
                    delay: i * 0.5,
                    onComplete: () => {
                        this.content.classList.add("is-loaded");
                    },
                });
            }
        });
    }

    play(device) {
        let svg;
        if (device) {
            svg = device === "pc" ? this.content.querySelector(".is-pc") : this.content.querySelector(".is-mo");
        } else {
            svg = this.content;
        }

        const duration = window.innerWidth > 768 ? 1 : 0.7;
        const _ease = window.innerWidth > 768 ? "power2.out" : "power1.out";
        const _rect = gsap.utils.toArray(".typo-mask", svg);

        if (!this.played) {
            if (this.line) {
                this.drawLine(device);
            }
            gsap.utils.toArray(".typo-mask", svg).forEach((typo, i) => {
                if (this.line) {
                    if (!typo.hasAttribute("data-load")) {
                        typo.setAttribute("data-load", true);

                        gsap.to(typo, {
                            width: "100%",
                            duration: duration,
                            ease: _ease,
                            delay: this.stagger * parseInt(typo.getAttribute("data-index")) + 0.1,
                        });
                    }
                } else {
                    if (!typo.hasAttribute("data-load")) {
                        typo.setAttribute("data-load", true);

                        gsap.to(typo, {
                            width: "100%",
                            duration: duration,
                            ease: _ease,
                            delay: this.stagger * typo.getAttribute("data-index"),
                            onStart: () => {
                                if (i == this.typos.length - 1) {
                                    if (this.callback) {
                                        gsap.delayedCall(duration * 0.4, () => {
                                            this.callback();
                                        });
                                    }
                                }
                            },
                            onComplete: () => {
                                if (i == _rect.length - 1) {
                                    this.content.classList.add("is-loaded");
                                }
                            },
                        });
                    }
                }
            });
        }

        ScrollTrigger.refresh();
    }

    anim() {
        if (this.line) {
            // console.log("is g ", this.content.querySelector("svg.is-pc g"));
            const that = this;
            ScrollTrigger.matchMedia({
                "(min-width: 769px)": function () {
                    // console.log(3, that);
                    // large
                    const _trigger = that.content.querySelector(".is-pc");

                    ScrollTrigger.create({
                        trigger: that.content,
                        start: () => `top+=${100 / _trigger.getAttribute("data-length")}% bottom`,
                        once: true,

                        onEnter: () => {
                            // return;
                            that.play("pc");
                        },
                    });
                },

                // small
                "(max-width: 768px)": function () {
                    const _trigger = that.content.querySelector(".is-mo");

                    ScrollTrigger.create({
                        trigger: that.content,
                        start: `top+=${100 / _trigger.getAttribute("data-length")}% bottom`,
                        // start: () => `top-=2px bottom`,
                        // end: () => `+=10000px`,
                        once: true,
                        // immediateRender: false,
                        // normalizeScroll: false,
                        // markers: true,
                        id: "SVG",
                        onEnter: () => {
                            // alert(3)
                            that.play("mo");
                        },
                    });
                },
            });

            ScrollTrigger.refresh();

            // gsap.set(this.content.querySelector("svg.is-pc .fill-line"), { backgroundColor: "orange" });
        } else {
            if (this.isFooter) {
                if (window.innerWidth > 768) {
                    ScrollTrigger.create({
                        trigger: document.querySelector(".footer"),
                        start: () => `${this.triggerStart} ${this.isMobile ? `bottom` : `bottom`}`,
                        once: true,
                        // //
                        id: "svg",
                        // // //
                        onEnter: () => {
                            // return;
                            this.play();
                        },
                    });
                } else {
                    const _trigger = this.content.querySelector(".is-mo");
                    ScrollTrigger.create({
                        trigger: _trigger,
                        start: `top+=${100 / _trigger.getAttribute("data-length")}% bottom`,
                        once: true,
                        //
                        // markers: true,
                        id: "svg",
                        // // //
                        onEnter: () => {
                            // return;
                            alerrt("enter");
                            this.play();
                        },
                    });
                }
            } else {
                ScrollTrigger.create({
                    trigger: this.content,
                    start: () => `${this.triggerStart} ${this.isMobile ? `bottom-=${window.innerHeight * 0.26}px` : `bottom`}`,
                    once: true,
                    // markers: this.isFooter ? true : false,
                    id: "svg",
                    // // //
                    onEnter: () => {
                        // return;
                        this.play();
                    },
                });
            }
        }
    }
}
