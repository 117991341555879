import p5 from "p5";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollSmoother from "gsap/dist/ScrollSmoother";
import ScrollToPlugin from "gsap/ScrollToPlugin";
// swiper
import "swiper/css";
import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./style/common.css";
import { TextFill, TextFilp, TextMasking, TextTranslate, ImageContast, ButtonExtend } from "./Module";
import CardExtend from "./CardExtend";
import CardBGAnim from "./CardBGAnim";
import sketch from "./visualAbout";
import sketchInner from "./visualPin";
import SVGText from "./SVGText";

export default class GTAA_About {
    constructor() {
        this.init();
        this.isMobile = document.body.classList.contains("is-mobile");
    }

    init() {
        gsap.delayedCall(0.2, () => {
            this.sectionMain();
        });
        gsap.delayedCall(0.01, () => {
            this.sectionObjectives();
            this.sectionAbout();
            this.sectionFounding();
            this.sectionFoundingMembers();
            // this.sectionProgram();
            // this.sectionSpeaker();
            // this.sectionRound();
            // this.sectionInnovation()
            // this.sectionMilestones()
            // this.sectionTelcoLLM();

            // this.sectionExpect();

            ScrollTrigger.refresh();
        });
        gsap.delayedCall(0.3, () => {
            this.sectionKeyvisual();
        });
    }

    sectionMain() {
        const section = document.querySelector(".section-main");

        const _svg = new SVGText({ content: section.querySelector(".new-typo-container"), isNotTrigger: true, stagger: 0.2 });
        if (window.innerWidth > 768) {
            _svg.play("pc");
        } else {
            _svg.play("mo");
        }
        ScrollTrigger.refresh();
    }

    sectionKeyvisual() {
        const sketchInstance = new p5(sketch);
        ScrollTrigger.create({
            trigger: ".section-main",
            start: () => `top-=30px top`,
            end: () => `bottom top`,
            id: "p5",
            onToggle: (self) => {
                if (self.isActive) {
                    sketchInstance.playLoop();
                } else {
                    sketchInstance.stopLoop();
                }
            },
        });
    }

    sectionAbout() {
        const section = document.querySelector(".section-about");
        const sketchInstance = new p5(sketchInner);
        ScrollTrigger.create({
            trigger: section,
            start: () => `top-=21% top`,
            end: () => `bottom top`,

            id: "canvas",
            onToggle: (self) => {
                if (self.isActive) {
                    sketchInstance.playLoop();
                } else {
                    sketchInstance.stopLoop();
                }
            },
        });

        // gsap.set(section, { backgroundColor: "violet" });
        const kv = section.querySelector(".sticky-visual");
        ScrollTrigger.create({
            trigger: section,
            start: () => `top top-=10%`,
            end: () => `300% top`,
            pin: kv,
            pinType: "fixed",
            pinSpacing: false,
            id: "pincanvas",
        });

        gsap.to(kv.querySelector(".inner-canvas"), {
            y: "8%",
            opacity: 1,
            scrollTrigger: {
                trigger: section,
                id: "to show",
                // // // // markers: true,
                start: () => `top-=15% top`,
                end: () => `+=${window.innerHeight * 0.8}`,
                scrub: this.isMobile ? 1 : 2,
                // // // // // markers: true,
            },
        });
        gsap.to(kv, {
            opacity: 0,
            scrollTrigger: {
                trigger: ".section-objectives",
                start: () => `10% bottom`,
                end: () => `+=${window.innerHeight * 1.0}`,
                scrub: 1,
                // // // // markers: true,
                id: "to hide",
            },
        });

        ScrollTrigger.refresh();
    }

    sectionObjectives() {
        const section = document.querySelector(".section-objectives");

        new SVGText({ content: section.querySelector(".new-typo-container"), stagger: 0.2 });

        gsap.utils.toArray(".content-card", section).forEach((container, i) => {
            const mask = container.querySelector(".graphic-mask");
            const bg = container.querySelector(".card-background");

            const _stagger = window.innerWidth > 768 ? 0.2 * i : 0;
            ScrollTrigger.create({
                trigger: container.querySelector(".card-graphic"),
                start: () => (this.isMobile ? `top+=25% bottom` : `top+=50% bottom`),
                once: true,

                id: "card",
                onEnter: () => {
                    gsap.to(mask, { width: "100%", duration: 1.0, ease: "power3.out", delay: _stagger });
                },
            });

            const _callback = () => {
                gsap.to(bg, { opacity: 1, duration: 1.0, ease: "power1.inOut", delay: _stagger });
            };

            const _container = container.querySelector(".txt-split-wrap");
            new TextMasking({ container: _container, callbackStart: _callback, callbackTiming: 0.3, startDelay: _stagger });
        });
    }

    sectionFounding() {
        const section = document.querySelector(".section-founding");
        new SVGText({ content: section.querySelector(".new-typo-container"), stagger: 0.2 });
        console.log(section.querySelector(".map"))
        ScrollTrigger.create({
            trigger: section.querySelector(".section-full"),
            start: () => `top+=30% bottom`,
            once: true,
            // markers: true,
            id: "map",
            onEnter: () => {
                const _duration = 0.6;
                gsap.utils.toArray(".member", section).forEach((m, i) => {
                    gsap.to(m.querySelector("img"), {
                        y: "0%",
                        duration: _duration,
                        ease: "expo.out",
                        delay: i * 0.12,
                    });
                });
            },
        });
    }

    sectionRound() {
        const section = document.querySelector(".section-roundtable");

        gsap.utils.toArray(".image-wrap", section).forEach((content, i) => {
            new ImageContast({ content: content, delay: i * 0.15 });
            // console.log({ i });
        });
    }
    sectionInnovation() {
        const section = document.querySelector(".section-innovation");

        new CardBGAnim(section.querySelectorAll(".content-card"));
    }

    sectionFoundingMembers() {
        const section = document.querySelector(".section-founding-members");

        // gsap.utils.toArray(".typo-mask-container", section).forEach((content, i) => {
        //     const _direction = content.getAttribute("data-direction");
        //     const _text = new TextFill({ content: content, isUp: true });

        // });
        // gsap.utils.toArray(".content-card", section).forEach((container, i) => {
        //     new CardExtend({ container: container, bgScale: 0.35 });
        // });

        gsap.utils.toArray(".content-card", section).forEach((container, i) => {
            const bg = container.querySelector(".card-background");
            const stagger = window.innerWidth > 768 ? container.getAttribute("data-index") * 0.15 : 0;

            // console.log(stagger);
            gsap.set(bg, {
                scale: 0.1,
                transformOrigin: "50% 50%",
            });
            ScrollTrigger.create({
                trigger: container,
                start: () => `top+=20% bottom`,
                once: true,
                onEnter: () => {
                    gsap.to(bg, { opacity: 1, duration: 0.7, ease: "power2.inOut", delay: stagger });
                    gsap.to(bg, { scale: 1, duration: 1.0, ease: "power2.out", delay: stagger });
                },
            });
        });
    }
}
