import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

export default class CardBGAnim {
    constructor(contents) {
        this.contents = contents;
        this.isMobile = document.body.classList.contains("view-mobile");

        this.init();
    }

    init() {
        this.initStyle();
    }

    initStyle() {
        this.contents.forEach((card, i) => {
            const _bg = card.querySelector(".card-background");
            const _sub = card.querySelectorAll(".txt-small");
            const _graphic = card.querySelector(".card-graphic");

            if (_graphic) {
                gsap.set(_graphic, {
                    scale: 2.5,
                    transformOrigin: "50% 50%",
                    y: "0%",
                    opacity: 0,
                });

                if (i == 0) {
                    gsap.set(_graphic, {
                        // x: "12%",
                        // y: "-50%",
                        y: "40%",
                    });
                }
                if (i == 1) {
                    gsap.set(_graphic, {
                        // x: "150%",
                        // y: "-60%",
                    });
                }
                if (i == 2) {
                    gsap.set(_graphic, {
                        // transformOrigin: "50% 0%",
                        // x: "65%",
                        y: "80%",
                    });
                }
            }

            gsap.set(_sub, { y: window.innerWidth > 768 ? 400 : 150 });
        });

        this.anim();
    }

    anim() {
        this.contents.forEach((card, i) => {
            const _bg = card.querySelector(".card-background");
            const _sub = card.querySelectorAll(".txt-small");
            const _graphic = card.querySelector(".card-graphic");

            const _delay = 0.15 * i;
            ScrollTrigger.create({
                trigger: card,
                start: () => `top+=15% bottom`,
                // //
                // markers: true,
                id: "card",
                once: true,
                onEnter: () => {
                    // gsap.to(_graphic, { opacity: 1, duration: 0.8, delay: 0.0, ease: "power1.out" });
                    // gsap.to(_graphic, { scale: 1, y: "0%", duration: 2, delay: 0.0, ease: "power2.out" });

                    gsap.to(_sub, {
                        y: 0,
                        duration: 0.9,
                        ease: "power4.out",
                        delay: _delay,
                    });
                    gsap.to(_sub, {
                        opacity: 1,
                        duration: 0.9,
                        ease: "power4.inOut",
                        delay: _delay,
                    });
                },
            });

            ScrollTrigger.create({
                trigger: card,
                start: () => "top+=85% bottom",
                //
                id: "card",
                once: true,
                onEnter: () => {
                    gsap.to(_bg, { autoAlpha: 1, duration: 1, delay: 0.5, ease: "power1.inOut" });

                    gsap.to(_graphic, { opacity: 1, duration: 0.8, delay: 0.0, ease: "power1.inOut" });
                    gsap.to(_graphic, { scale: 1, duration: 1.2, delay: 0.0, ease: "power2.out" });
                    gsap.to(_graphic, { x: "0%", y: "0%", duration: 1.2, delay: 0.0, ease: "power2.out" });
                },
            });
        });
    }
}
