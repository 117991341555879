import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
export default class CardImageText {
    constructor(props) {
        const { content, index, isLight, triggerStart } = props;
        this.isLight = isLight ?? false;

        this.value = {
            // contrastValue: this.isLight ? 0 : 0,
            // brightnessValue: this.isLight ? 2 : 0,
            contrastValue: 2,
            brightnessValue: 0.5,
            saturateValue: 0,
        };

        this.triggerStart = triggerStart ?? `top+=50% bottom`;

        if (this.isMobile) {
        }

        this.content = content;
        this.index = index ?? 0;
        this.image = this.content.querySelectorAll("img");

        gsap.set(this.image, { opacity: 0 });

        // if (this.isLight) {

        //     gsap.set(this.content, { border: "3px solid blue" });
        // }

        this.init();
    }

    init() {
        this.updateStyle();

        this.anim();
    }

    updateStyle() {
        gsap.set(this.image, { filter: `saturate(${this.value.saturateValue}) contrast(${this.value.contrastValue})` });
    }

    anim() {
        ScrollTrigger.create({
            trigger: window.innerWidth > 768 ? this.content : this.image[this.image.length - 1],
            start: () => this.triggerStart,
            once: true,
            id: this.index,
            // markers: true,
            onEnter: () => {
                gsap.to(this.value, {
                    contrastValue: 1.0,
                    duration: 0.9,
                    ease: "power2.inOut",
                    delay: window.innerWidth > 768 ? 0.15 * this.index : 0,
                });
                gsap.to(this.value, {
                    saturateValue: 1.0,
                    duration: 0.9,
                    ease: "power4.inOut",
                    delay: window.innerWidth > 768 ? 0.15 * this.index : 0,
                    onUpdate: () => this.updateStyle(),
                });
                gsap.to(this.image, {
                    opacity: 1,
                    duration: 0.9,
                    ease: "power4.inOut",
                    delay: window.innerWidth > 768 ? 0.15 * this.index : 0,
                });
            },
        });
    }
}
