import p5 from "p5";
import gsap from "gsap";

export default function sketch(s) {
    var img;
    var isDraw = false;
    s.preload = () => {
        img = s.loadImage('./line-about.png');
    }

    var scale = 0.5;

    var stageWidth = 1800 * scale;
    var stageHeight = 1200 * scale;

    var cx, cy;

    class Obj {
        constructor(idx, dir){
            this.idx = idx;
            this.dir = dir;
            this.minHeight = 1500;
            this.lineHeight = this.minHeight * 1.1;
            this.x = this.idx * (lineWidth + 2000) * this.dir;
        }

        changeState(val){
            if(val == 1){
                var th = 250 + this.idx * 60;
                gsap.to( this, { duration: 4, lineHeight: th, ease: "expo.inOut", delay: this.idx * 0.1});
                gsap.delayedCall(0.5, this.inXpostion.bind(this))
            }else{
                var th = this.minHeight * 1.1;            
                gsap.to( this, { duration: 2.2, lineHeight: th, ease: "expo.inOut", delay: this.idx * 0.08});
                gsap.delayedCall(1.5, this.outXposition.bind(this))
            }
        }

        inXpostion(){
            var tx = this.idx * (lineWidth + 18) * this.dir;               
            gsap.to( this, { duration: 2.6, x: tx, ease: "power4.out", delay: 0.07 * this.idx});
        }

        outXposition(){
            var that = this;
            var tx = this.idx * (lineWidth + 2000) * this.dir;
            gsap.to( this, { duration: 2.5, x: tx, ease: "expo.inOut", delay: 12 * 0.08 - this.idx * 0.08, onComplete: function(){
                that.lineHeight = that.minHeight * 1.1;
            }});
        }

        update(){
            var tspace = s.space * this.dir;
            var tx = cx + tspace + this.x;
            var th = this.lineHeight + s.sin(s.frameCount * 1.8 - this.idx * 20) * this.lineHeight * 0.2;
            var ty = cy - th * .5;

            // s.tint(255, 255);
            s.image(img, tx, ty, lineWidth, th);
        }
    }

    var objs = [];

    s.setup = () => {
        const p = s.createCanvas(stageWidth, stageHeight);
        p.parent("canvas-wrap");
        s.angleMode(s.DEGREES)

        const _v = window.innerWidth / p.elt.clientWidth;
        gsap.set("#canvas-wrap", { scale: _v < 1 ? 1 : _v.toFixed(2) });

        cx = stageWidth;
        cy = stageHeight;
        if(scale == 1){
            cx = stageWidth *.5;
            cy = stageHeight *.5;
        }

        for(var k = 0; k < 2; k++){
            for(var i = 0; i < 12; i++){
                var dir = 1;
                if(k % 2 == 1) dir = -1;
                objs.push(new Obj(i, dir));
            }
        }

        gsap.delayedCall(0.7, s.movIn);
    }


    s.lineSpace = 2000;
    s.space = s.lineSpace;
    var lineWidth = 20;

    s.draw = () => {
        s.scale(scale);
        s.background(0);
        
        for(var i = 0; i < objs.length; i++){
            var obj = objs[i];
            obj.update();
        }

        if (!isDraw) {
            isDraw = true;

            gsap.to(".section-main canvas", { opacity: 1, duration: 1.0, ease: "power1.inOut" });
        }
    }

    s.movIn = () => {
        for(var i = 0; i < objs.length; i++){
            var obj = objs[i];
            obj.changeState(1);
        }
        gsap.to( s, { duration: 3.0, space: 40, ease: "power4.out", delay: 0.5, onComplete: function(){
            gsap.delayedCall(2.0, s.movOut);
        } });
    }

    s.movOut = () => {
        for(var i = 0; i < objs.length; i++){
            var obj = objs[i];
            obj.changeState(0);
        }
        gsap.to( s, { duration: 3.0, space: 10, ease: "power1.inOut", delay: 1.0, onComplete: function(){

        } });
        gsap.to( s, { duration: 1.9, space: 1200, ease: "expo.inOut", delay: 2.8, onComplete: function(){
            gsap.delayedCall(0.3, s.movIn);
        } });
    }

    s.playLoop = () => {
        s.loop();
    };

    s.stopLoop = () => {
        s.noLoop();
    };
}