import p5 from "p5";
import gsap from "gsap";

export default function sketch(s) {
    var img;
    var isDraw = false;
    s.preload = () => {
        img = s.loadImage("./line.png");
    };

    var stageWidth = 900;
    var stageHeight = 600;

    var cx, cy;

    s.setup = () => {
        const p = s.createCanvas(stageWidth, stageHeight);
        p.parent("canvas-wrap");
        s.angleMode(s.DEGREES);
        cx = s.width / 2;
        cy = s.height / 2;
    }

    var lineSpace = 15;
    var lineWidth = 16;

    s.draw = () => {
        s.scale(0.5);
        s.background(255);
        
        for(var k = 0; k < 2; k++){
            for(var i = 0; i < 30; i++){
                var lh = 420// + s.cos(s.frameCount * 1.0 + i * 10) * 400 * 0.5;
                var tx = cx * 2 + i * (lineWidth + lineSpace)
                var ty = cy + 360 + s.cos(s.frameCount * 2.5 + i * 10) * 80;
                if(k == 1){
                    ty = cy - 150 + s.sin(s.frameCount * 2.5 + i * 10) * 80;
                }
                s.image(img, tx, ty, lineWidth, lh);
            }
        }
        if (!isDraw) {
            isDraw = true;

            gsap.to(".section-main canvas", { opacity: 1, duration: 1.0, ease: "power1.inOut" });
        }
    }
    s.playLoop = () => {
        s.loop();
    };

    s.stopLoop = () => {
        s.noLoop();
    };
}