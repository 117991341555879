import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import TextMasking from "./TextMasking";

export default class CardExtend {
    constructor(props) {
        const { container, bgScale, triggerStart, triggerEnd } = props;

        // this.bgScale = bgScale ?? 0.2;
        // this.triggerStart = triggerStart ?? "top+=50% bottom";
        this.isMobile = document.body.classList.contains("view-mobile");

        this.triggerStart = triggerStart ?? "top+=30% bottom";
        // this.triggerEnd = triggerEnd ?? "top-=10% 20%";

        this.container = container;
        this.background = this.container.querySelector(".graphic-background");
        this.textSub = this.container.querySelector(".txt-container .txt-xsmall");
        this.symbol = this.container.querySelector(".graphic-symbol");

        this.bgScale = 0.01;
        this.init();

        this.textMasking;
    }

    init() {
        // console.log("new CardExtend");
        this.initStyle();

        const _text = this.container.querySelector(".txt-split-wrap");
        this.textMasking = new TextMasking({ container: _text, isNoTrigger: true });
    }

    initStyle() {
        gsap.set(this.background, { scaleX: this.bgScale });
        // gsap.set(this.textSub, { opacity: 0 });
        this.anim();
    }

    playBackground() {
        gsap.to(this.background, { scaleX: 1, duration: 0.7, ease: "power1.out" });
        gsap.to(this.background, { opacity: 1, duration: 0.7, ease: "power1.inOut" });

        gsap.to(this.symbol, { opacity: 1, duration: 0.55, ease: "power1.inOut", delay: 0.45 });
    }

    anim() {
        const that = this;
        ScrollTrigger.matchMedia({
            "(min-width: 769px)": function () {
                // console.log(3, that);
                // large
                ScrollTrigger.create({
                    trigger: that.container,
                    start: () => that.triggerStart,
                    once: true,
                    onEnter: () => {
                        that.playBackground();
                        that.textMasking.play();
                        // gsap.to(this.textSub, { opacity: 1, duration: 0.8, ease: "power1.inOut" });
                    },
                });
            },

            // small
            "(max-width: 768px)": function () {
                ScrollTrigger.create({
                    trigger: that.container.querySelector(".txt-split-wrap"),
                    start: () => `50% bottom`,
                    once: true,
                    onEnter: () => {
                        that.textMasking.play();
                        // gsap.to(this.textSub, { opacity: 1, duration: 0.8, ease: "power1.inOut" });
                    },
                });
                ScrollTrigger.create({
                    trigger: that.container,
                    start: () => `50% bottom`,
                    once: true,
                    onEnter: () => {
                        that.playBackground();
                        // gsap.to(this.textSub, { opacity: 1, duration: 0.8, ease: "power1.inOut" });
                    },
                });
            },
        });
    }
}
